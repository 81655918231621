import React, { useEffect, useState } from "react";
import DownBlackArrow from "../assets/images/down_black_arrow.svg";
import UpWhiteArrow from "../assets/images/up_white_arrow.svg";
import axios from "axios";

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <div
    className={`mb-4 ${
      isOpen ? "text-white rounded-xl bg-primary" : "text-dark"
    }`}
  >
    <div
      className={`w-full text-left focus:outline-none rounded-xl flex justify-between ${
        isOpen ? "px-6 pt-6 max-sm:pt-3 pb-2" : "p-4 border border-lighter"
      }`}
      onClick={onClick}
    >
      <div className="w-[90%]">
        <h4 className="text-xl max-sm:text-md font-medium flex justify-between items-center">
          {title}
        </h4>
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <span
          className="transform transition-transform duration-300"
          style={{ marginLeft: isOpen && "10px" }}
        >
          <img
            src={isOpen ? UpWhiteArrow : DownBlackArrow}
            className="min-w-[9.67px] min-h-[15.9px]"
            alt="arrow"
            width={"9.67px"}
            height={"15.9px"}
          />
        </span>
      </div>
    </div>
    {isOpen && (
      <div className="px-5 pb-4 bg-primary rounded-xl">
        <div className="flex">
          <div className="border rounded-xl border-white" />
          <p className="pl-3 w-[73%] max-sm:w-[100%] text-md max-sm:text-base font-normal leading-relaxed ">
            {content}
          </p>
        </div>
      </div>
    )}
  </div>
);

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.mixxerco.com/api/app/faqs"
        );
        setFaqs(response.data.data);
        // console.log(response.data.data);
      } catch (err) {
        console.log("error fetch data ", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="rounded-md">
      {loading ? (
        <div className="flex justify-center">
          <div class="loader"></div>
        </div>
      ) : (
        faqs.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.question}
            content={item.answer}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))
      )}
    </div>
  );
};

export default FAQSection;
