import React from "react";
import Layout from "../layout";

const Disclaimer = () => {
  return (
    <>
      <div className="bg-light w-[80%] mx-auto py-20">
        <h1 className="text-12xl max-sm:text-5xl font-lightBold text-dark mb-4">
          Disclaimer For Mixxer
        </h1>
        <h1 className=" text-2xl font-medium text-dark">Disclaimer</h1>
        <p className=" text-lightbrown text-lg font-normal mb-4">
          If you require any more information or have any questions about our
          site's disclaimer, please feel free to contact us by email at
          <a
            href="mailto:contactus@mixxerco.com"
            className="text-blue-600 hover:underline"
          >
            {" "}
            contactus@mixxerco.com
          </a>
          .
        </p>

        {/* <h2 className=" text-2xl font-medium text-dark">
          Disclaimers for Mixxer
        </h2> */}
        <p className=" text-lightbrown text-lg font-normal mb-4">
          All the information on this website -
          <a
            href="https://www.mixxerco.com"
            className="text-blue-600 hover:underline"
          >
            {" "}
            www.mixxerco.com
          </a>{" "}
          - is published in good faith and for general information purposes
          only. Mixxer Technologies, LLC ("Mixxer") does not make any warranties
          about the completeness, reliability, and accuracy of this information.
          Any action you take upon the information you find on this website
          (Mixxer), is strictly at your own risk. Mixxer will not be liable for
          any losses and/or damages in connection with the use of our website.
        </p>

        <p className=" text-lightbrown text-lg font-normal mb-4">
          From our website, you can visit other websites by following hyperlinks
          to such external sites. While we strive to provide only quality links
          to useful and ethical websites, we have no control over the content
          and nature of these sites. These links to other websites do not imply
          a recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone 'bad'.
        </p>

        <p className=" text-lightbrown text-lg font-normal mb-4">
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </p>

        <h2 className=" text-2xl font-medium text-dark mb-1">Consent</h2>
        <p className=" text-lightbrown text-lg font-normal mb-4">
          By using our website, you hereby consent to our disclaimer and agree
          to its terms.
        </p>

        <h2 className=" text-2xl font-medium text-dark mb-1">Update</h2>
        <p>
          Should we update, amend, or make any changes to this document, those
          changes will be prominently posted here.
        </p>
      </div>
    </>
  );
};

export default Layout(Disclaimer);
