import React from "react";
import Layout from "../layout";

const EULASection = () => {
  return (
    <>
      <div className="leading-[30px] w-[80%] mx-auto bg-light mt-8 py-20">
        <h1 className="leading-[30px] text-12xl max-sm:text-5xl font-lightBold mb-6">
          End User License Agreement
        </h1>

        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          {`This copy of Mixxer ("the Software Product") and accompanying
          documentation is licensed and not sold. This Software Product is
          protected by copyright laws and treaties, as well as laws and treaties
          related to other forms of intellectual property. Mixxer
          LLC or its subsidiaries, affiliates, and suppliers (collectively
          "Mixxer") own intellectual property rights in the Software Product.
          The Licensee's ("you" or "your") license to download, use, copy, or
          change the Software Product is subject to these rights and to all the
          terms and conditions of this End User License Agreement ("Agreement").`}
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">Acceptance</h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          You accept and agree to be bound by the terms of this agreement by
          selecting the "accept" option and downloading the software product or
          by installing, using, or copying the software product. You must agree
          to all of the terms of this agreement before you will be allowed to
          download the software product. If you do not agree to all of the terms
          of this agreement, you must select "decline" and you must not install,
          use, or copy the software product.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">License Grant</h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          This Agreement entitles you to install and use one copy of the
          Software Product. In addition, you may make one archival copy of the
          Software Product. The archival copy must be on a storage medium other
          than a hard drive and may only be used for the reinstallation of the
          Software Product. This Agreement does not permit the installation or
          use of multiple copies of the Software Product, or the installation of
          the Software Product on more than one computer at any given time, on a
          system that allows shared use of applications, on a multi-user
          network, or on any configuration or system of computers that allows
          multiple users. Multiple copy use or installation is only allowed if
          you obtain an appropriate licensing agreement for each user and each
          copy of the Software Product.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Restrictions on Transfer
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          Without first obtaining the express written consent of Mixxer, you may
          not assign your rights and obligations under this Agreement, or
          redistribute, encumber, sell, rent, lease, sublicense, or otherwise
          transfer your rights to the Software Product.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Restrictions on Use
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          You may not use, copy, or install the Software Product on any system
          that exceeds the scope of your license agreement. This includes
          installing or accessing the Software Product on multiple devices
          unless explicitly permitted by your license, using the Software
          Product in a multi-user network environment, shared hosting service,
          or cloud-based platform without specific authorization from Mixxer, or
          allowing unauthorized users or third parties to access the Software
          Product in any manner not explicitly granted by this Agreement. If
          your license permits use on multiple devices or systems, each
          installation must comply with the terms specified for your license
          tier.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          {" "}
          Restrictions on Alteration
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          {`You may not modify the Software Product or create any derivative work
          of the Software Product or its accompanying documentation. Derivative
          works include but are not limited to translations. You may not alter
          any files or libraries in any portion of the Software Product. You may
          not reproduce the database portion or create any tables or reports
          relating to the database portion.`}
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Restrictions on Copying
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          You are permitted to create one archival copy of the Software Product
          for backup purposes. This copy may be stored on a secure medium, such
          as an external hard drive, encrypted cloud storage, or similar storage
          solutions, provided it is not used for active installations or
          simultaneous access. Temporary copies required for normal operation of
          the Software Product, such as caching or memory storage, are also
          permitted but must not be retained beyond their intended purpose.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Disclaimer of Warranties and Limitation of Liability
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          Unless otherwise explicitly agreed to in writing by mixxer, mixxer
          makes no other warranties, express or implied, in fact or in law,
          including, but not limited to, any implied warranties of
          merchantability or fitness for a particular purpose other than as set
          forth in this agreement or in the limited warranty documents provided
          with the software product. Mixxer makes no warranty that the software
          product will meet your requirements or operate under your specific
          conditions of use. Mixxer makes no warranty that operation of the
          software product will be secure, error-free, or free from
          interruption. You must determine whether the software product
          sufficiently meets your requirements for security and
          uninterruptibility. You bear sole responsibility and all liability for
          any loss incurred due to failure of the software product to meet your
          requirements. Mixxer will not, under any circumstances, be responsible
          or liable for the loss of data on any computer or information storage
          device. Under no circumstances shall mixxer, its directors, officers,
          employees or agents be liable to you or any other party for indirect,
          consequential, special, incidental, punitive, or exemplary damages of
          any kind (including lost revenues or profits or loss of business)
          resulting from this agreement, or from the furnishing, performance,
          installation, or use of the software product, whether due to a breach
          of contract, breach of warranty, or the negligence of mixxer or any
          other party, even if mixxer is advised beforehand of the possibility
          of such damages. To the extent that the applicable jurisdiction limits
          mixxer's ability to disclaim any implied warranties, this disclaimer
          shall be effective to the maximum extent permitted.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Limitation of Remedies and Damages
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          {`Your remedy for a breach of this Agreement or of any warranty included
          in this Agreement is the correction or replacement of the Software
          Product. Selection of whether to correct or replace shall be solely at
          the discretion of Mixxer. Mixxer reserves the right to substitute a
          functionally equivalent copy of the Software Product as a replacement.
          If Mixxer is unable to provide a replacement or substitute Software
          Product or corrections to the Software Product, your sole alternate
          remedy shall be a refund of the purchase price for the Software
          Product exclusive of any costs for shipping and handling.Any claim
          must be made within the applicable warranty period. All warranties
          cover only defects arising under normal use and do not include
          malfunctions or failure resulting from misuse, abuse, neglect,
          alteration, problems with electrical power, acts of nature, unusual
          temperatures or humidity, improper installation, or damage determined
          by Mixxer to have been caused by you. All limited warranties on the
          Software Product are granted only to you and are non-transferable. You
          agree to indemnify and hold Mixxer harmless from all claims,
          judgments, liabilities, expenses, or costs arising from your breach of
          this Agreement and/or acts or omissions.`}
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">
          Governing Law, Jurisdiction and Costs
        </h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          This Agreement is governed by the laws of Texas, without regard to
          Texas's conflict or choice of law provisions.
        </p>

        <h2 className="leading-[30px] text-2xl font-medium">Severability</h2>
        <p className="leading-[30px] mb-6 text-lg font-normal text-lightbrown">
          If any provision of this Agreement shall be held to be invalid or
          unenforceable, the remainder of this Agreement shall remain in full
          force and effect. To the extent any express or implied restrictions
          are not permitted by applicable laws, these express or implied
          restrictions shall remain in force and effect to the maximum extent
          permitted by such applicable laws.
        </p>
      </div>
    </>
  );
};

export default Layout(EULASection);
